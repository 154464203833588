<template>
  <div>
    <slot :open-modal="openModal">
      <button
        v-if="showButton"
        type="button"
        :class="iconClass"
        class="btn cursor-pointer p-0.5"
        @click="openModal()">
        <InlineSvg
          class="w-6 h-6"
          :src="require('@/assets/fontawesome/regular/money-bill.svg')"/>
      </button>
    </slot>

    <Modal
      v-slot="{ closeModal }"
      ref="modalTips"
      name="Tips"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      @close="clearState">
      <Form class="relative">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="inline-block text-gray-900 p-1 w-5 h-5 absolute right-0 top-0 cursor-pointer"
          @click="closeModal"/>

        <div class="flex flex-col items-center justify-center mb-6">
          <Avatar
            class="w-20 mb-4"
            :user-item="user"/>

          <div class="flex items-center">
            <h3>
              {{ $t('creator.tips.show_support_for', { name: user.name }) }}
            </h3>

            <InlineSvg
              v-if="user.tikTokFollowerCount"
              :src="require('@/assets/svg/design/verified.svg')"
              class="ml-0.5 shrink-0"/>
          </div>

          <p class="h3 text-gray-500">
            @{{ user.username }}
          </p>
        </div>

        <Form
          id="credits-form-1"
          ref="proceedToPaymentForm"
          v-slot="{ errors, setErrors }"
          :initial-values="initialValues"
          @submit="makeDonation">
          <div class="relative mb-7">
            <div class="relative">
              <Field
                id="amount"
                :placeholder="$t('common.amount')"
                :rules="rules.amount"
                name="amount"
                :min="0"
                :max="user.tipAmountMax"
                :validate-on-blur="false"
                :validate-on-change="false"
                :validate-on-input="false"
                type="number"
                class="form-input rounded-2.5xl py-3 pr-18"
                @focus="setErrors(fieldsToReset)"/>

              <div class="absolute top-0 bottom-0 right-4 flex items-center">
                <span class="text-sm text-gray-400">
                  Min ${{ user.tipAmountMin }}
                </span>
              </div>
            </div>

            <div
              v-if="errors.amount"
              class="absolute right-0 -bottom-6 text-caption text-red-500">
              {{ $t(errors.amount.key, errors.amount.values) }}
            </div>
          </div>

          <div class="relative mb-5">
            <Field
              id="comment"
              as="textarea"
              :placeholder="$t('creator.tips.optional_comment')"
              :rules="rules.comment"
              name="comment"
              :validate-on-blur="false"
              :validate-on-change="false"
              :validate-on-input="false"
              type="text"
              class="form-input rounded-2.5xl py-3 resize-none"
              @focus="setErrors(fieldsToReset)"/>
          </div>

          <YupTooltip
            v-if="!paymentMethods.length"
            :i18n="errors.terms">
            <div class="flex items-start mb-1">
              <div class="h-5 flex items-center">
                <Field
                  id="terms"
                  type="checkbox"
                  name="terms"
                  class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
                  :value="true"
                  :rules="rules.terms"
                  @focus="setErrors(fieldsToReset)"/>
              </div>

              <div class="ml-3 text-sm">
                <label
                  for="terms"
                  class="font-medium text-gray-600 text-caption leading-4">
                  <VRuntimeTemplate :template="$t('creator.tips.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
                </label>
              </div>
            </div>
          </YupTooltip>

          <template v-if="!isLoading">
            <AlertMessage
              v-if="isPending"
              type="warning"
              class="mb-2.5"
              heading="Transaction pending, check later or contact online support"/>

            <AlertMessage
              v-else-if="isFailed || errorMessage"
              type="error"
              class="mb-2.5"
              :heading="errorMessage || 'Previous transaction failed'"
              :text="errorDescription"/>

            <AlertMessage
              v-else-if="showWarnUpdatingCard"
              type="info"
              class="mb-2.5 mt-2.5"
              heading="When making a payment, you will have to update your card details once more!"/>
          </template>

          <button
            type="submit"
            :disabled="isLoading || isPending"
            class="btn btn-block btn-primary mt-4 mb-3 h-[48px]">
            <span v-show="!isLoading">
              {{ $t('common.send') }}
            </span>

            <Spinner v-show="isLoading"/>
          </button>
        </Form>
      </form>
    </modal>
  </div>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import { Field, Form } from 'vee-validate'
import YupTooltip from '@/components/Functional/YupTooltip'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { mapState, mapActions, mapWritableState } from 'pinia'
import Spinner from '@/components/Functional/Spinner'
import { usePaymentStore } from '@/stores/payment'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import uniqid from 'uniqid'

// Docs: https://github.com/wiggumlab/fansy-server/commit/ffc7bfee9a5d053695ceac2efb2aac847986e147
export default {
  name: 'TipsModal',
  components: {
    Avatar,
    Field,
    Form,
    YupTooltip,
    Spinner,
    AlertMessage
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => ({})
    },
    strokeIconColor: {
      type: String,
      required: false,
      default: 'stroke-gray-400'
    },
    iconClass: {
      type: String,
      required: false,
      default: 'text-gray-400 hover:text-gray-500'
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isLoading: false,
      initialValues: {
        terms: false
      },
      fieldsToReset: {
        terms: false,
        comment: false,
        amount: false
      },
      errorMessage: null,
      errorDescription: null,
      successMessage: null
    }
  },
  computed: {
    tipAmountMax () {
      return this.user.tipAmountMax
    },
    tipAmountMin () {
      return this.user.tipAmountMin
    },
    rules () {
      return {
        amount: this.$yup.number()
          .required()
          .typeError({ key: 'yup.number.amount' })
          .max(this.tipAmountMax, ({ max }) => ({ key: 'yup.custom.amount_max', values: { max } }))
          .min(this.tipAmountMin, ({ min }) => ({ key: 'yup.custom.amount_min', values: { min } })),
        comment: this.$yup.string().nullable(),
        terms: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      }
    },
    ...mapState(usePaymentStore, [
      'activePaymentMethod',
      'paymentMethods'
    ]),
    ...mapWritableState(usePaymentStore, [
      'pathBeforeBuying',
      'tipsPayment',
      'showWarnUpdatingCard'
    ]),
    ...mapState(useAppStore, [
      'isMobile',
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ]),
    secondsLeftFromPayment () {
      if (this.tipsPayment) {
        return this.currentTime - this.tipsPayment.createdAt
      } else {
        return null
      }
    },
    isPending () {
      return this.tipsPayment && this.tipsPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.tipsPayment.status)
    },
    isFailed () {
      return this.tipsPayment && ['failed', 'cancelled'].includes(this.tipsPayment.status)
    },
    termsOfService () {
      return `<a
                href="${window.one2fan.origin}/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="${window.one2fan.origin}/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  watch: {
    async tipsPayment (payment) {
      if (payment?.status === 'succeeded') {
        this.$refs.modalTips.closeModal()
        this.purchaseDelete(payment.purchaseId)
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.isLoading = false
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.purchaseDelete(this.tipsPayment.purchaseId)
      }
    }
  },
  mounted () {},
  methods: {
    clearState () {
      this.errorMessage = null
      this.errorDescription = null
      this.isLoading = false
    },
    ...mapActions(usePaymentStore, [
      'checkoutSessionCreate',
      'purchase',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    async openModal () {
      if (this.tipsPayment) {
        const purchaseId = this.tipsPayment.purchaseId
        await this.purchaseGet(purchaseId)

        if (this.tipsPayment.status === 'created') {
          await this.purchaseCancel(purchaseId)
            .then(() => {
              this.purchaseDelete(purchaseId)
            })
        }
      }

      this.$refs.modalTips.openModal()
    },
    async makeDonation (values) {
      // 1. Есть сохраненная: карта stripe.purchase
      // 2. Нет сохраненной карты: stripe.purchase

      if (this.isPending) {
        return
      }

      this.errorMessage = null
      this.errorDescription = null

      // Remove failed purchase if payment initialized again
      if (['failed', 'cancelled'].includes(this.tipsPayment?.status)) {
        this.purchaseDelete(this.tipsPayment.purchaseId)
      }

      if (values.amount) {
        this.isLoading = true

        this.pathBeforeBuying = '/chat/' + this.user.userId

        const messageText = values.comment ? values.comment : ''
        const productId = JSON.stringify([this.user.userId, values.amount, messageText])

        if (this.paymentMethods.length) {
          // Check payment status or init one
          if (this.tipsPayment) {
            await this.purchaseGet(this.tipsPayment.purchaseId)
          } else {
            await this.purchaseCreate({ id: `tips_${values.amount}_${uniqid()}` })
          }

          if (this.endlessPaymentCreated) {
            await new Promise(resolve => setTimeout(resolve, 1000 * 5))
            this.isLoading = false
            return
          }

          await this.purchase({
            productType: 'tip',
            productId: productId,
            paymentMethodId: this.activePaymentMethod.id,
            successUrl: `${window.origin}/checkout/result?is=success&type=tip&userId=${this.user.userId}&cost=${values.amount}`,
            purchaseId: this.tipsPayment.purchaseId
          })
            .then(() => {
              this.errorMessage = null
              this.errorDescription = null

              this.$refs.modalTips.closeModal()
              this.isLoading = false
            })
            .catch((error) => {
              // Failed to accept the payment (purchase failed)
              if (error?.code === 147) {
                this.errorMessage = error?.message
                this.errorDescription = error?.description
              }
              // The product is already purchased (purchase failed)
              else if (error?.code === 105) {
                this.errorMessage = 'Product is already purchased'
              }
              // Payment is pending
              else if (error?.code === 149) {
                //
              }
              else {
                this.errorMessage = 'Error occured'
              }

              // Remove payment if code is Failed
              if ([147, 105].includes(error?.code)) {
                this.purchaseDelete(this.tipsPayment.purchaseId)
              }
            })
            .finally(async () => {
              if (this.tipsPayment) {
                await this.purchaseGet(this.tipsPayment.purchaseId)

                // If purchase not completed -> wait n seconds to show loading
                if (!['succeeded', 'failed', 'cancelled'].includes(this.tipsPayment?.status)) {
                  await new Promise(resolve => setTimeout(resolve, 1000 * 10))
                }
              }

              this.isLoading = false
            })
        } else {
          await this.checkoutSessionCreate({
            productType: 'tip',
            productId: productId,
            successUrl: `${window.origin}/checkout/result?is=success&type=tip&userId=${this.user.userId}&cost=${values.amount}`,
            cancelUrl: `${window.origin}/checkout/result?is=cancel&type=tip&userId=${this.user.userId}`,
            savePaymentMethod: true
          }).finally(() => {
            this.isLoading = false
          })
        }
      }
    }
  }
}
</script>

<style scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
