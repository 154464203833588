<script setup>
  import app from '@/main.js'

  defineProps({
    message: {
      type: Object,
      default: () => ({
        gift: {
          imageUrls: {
            'c160x160': null,
            'c320x320': null,
            'c640x640': null,
            'original': null,
            'r640x960': null
          }
        }
      }),
      required: true
    }
  })

  function dateToTime (value) {
    const date = app.$dayjs.unix(value)

    return date.format('h:mm A')
  }
</script>

<template>
  <div class="flex justify-end">
    <div class="flex flex-col items-end gap-0.5">
      <div class="w-32">
        <img
          :src="message.gift.imageUrl"
          class="w-full"
          alt="Gift">
      </div>

      <div class="flex items-center -mt-3">
        <span
          :class="[message.gift.priceInCredits === 0 ? 'text-white bg-primary rounded-full px-1.5 leading-4' : 'leading-3']"
          class="text-xs font-semibold">
          {{ message.gift.priceInCredits ? message.gift.priceInCredits : 'Free' }}
        </span>

        <InlineSvg
          v-if="message.gift.priceInCredits && message.gift.priceInCredits > 0"
          class="h-3.5"
          :src="require('@/assets/svg/design/coin.svg')"/>
      </div>

      <span class="text-gray-400 text-xs">
        {{ dateToTime(message.created) }}
      </span>

      <!-- Delivery status -->
      <div
        v-if="message.status !== 0"
        class="text-right text-caption text-gray-400 w-full noselect">
        <div class="flex items-center justify-end">
          <div class="flex items-center">
            <InlineSvg
              v-show="message.status === 9"
              :src="require('@/assets/svg/design/viewed.svg')"/>

            <span class="ml-1">
              {{ message.status === 8 ? $t('chat.delivered') : $t('chat.viewed') }}
            </span>
          </div>

          <div
            v-show="message.ttl || message.ttlAfterRead"
            class="flex items-center ml-1">
            <InlineSvg
              class="text-transparent"
              :src="require('@/assets/svg/design/timer.svg')"/>

            <span class="ml-1">
              Secret message
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>