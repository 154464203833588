<script setup>
  import { inject } from 'vue'
  import InlineSvg from 'vue-inline-svg'

  const openGiftsConfirmationSendModal = inject('openGiftsConfirmationSendModal')
  const sendFreeGift = inject('sendFreeGift')

  defineProps({
    item: {
      type: Object,
      default: () => ({
        imageUrl: null
      }),
      required: true
    }
  })

  async function giftsSend (item) {
    if (item.priceInCredits === 0) {
      sendFreeGift(item)
    } else {
      openGiftsConfirmationSendModal(item)
    }
  }
</script>

<template>
  <div class="flex flex-col items-center justify-center gap-2">
    <img
      :src="item.imageUrl"
      class="w-full cursor-pointer aspect-square"
      alt="Gift"
      @click="giftsSend(item)">

    <div
      class="flex items-center gap-1 cursor-pointer"
      @click="giftsSend(item)">
      <span
        :class="[item.priceInCredits === 0 ? 'text-white bg-primary rounded-full px-1.5' : 'text-gray-950']"
        class="text-xs font-semibold">
        {{ item.priceInCredits === 0 ? 'Free' : item.priceInCredits }}
      </span>

      <InlineSvg
        v-if="item.priceInCredits && item.priceInCredits > 0"
        class="h-4 w-4"
        :src="require('@/assets/svg/design/coin.svg')"/>
    </div>
  </div>
</template>