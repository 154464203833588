import { h, render, ref, onMounted } from 'vue'
import { uid } from 'radash'

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Usage

// import ModalWindow
// import ComponentContent
// modalWindowProps = { wrapperClass: 'max-w-2xl overflow-y-auto rounded-xl bg-white p-4 shadow-lg' }
// componentContent = { heading: 'hello world' }
// createComponent(ModalWindow, modalWindowProps, ComponentContent, componentContent)

function createComponent (WrapperComponent, wrapperProps = {}, ComponentContent = {}, componentProps = {}, id = null) {
  const el = document.createElement('div')
  document.body.appendChild(el)

  el.id = id || `modal-${uid(6)}`

  const Wrapper = {
    setup () {
      const isOpen = ref(false)

      function destroy () {
        if (el) {
          render(null, el)
          el.remove()
        }
      }

      onMounted(() => {
        isOpen.value = true
      })

      async function close () {
        isOpen.value = false

        setTimeout(() => {
          destroy()
        }, 200)
      }

      return () =>
        h(WrapperComponent, { close: close, isOpen: isOpen.value, ...wrapperProps }, () =>
          h(ComponentContent, { close, ...componentProps })
        )
    }
  }

  render(h(Wrapper), el)

  return {
    destroy () {
      if (wrapperProps.destroy) {
        wrapperProps.destroy()
      }
    }
  }
}

function redirectToNewDomain (newDomain = 'https://one2fan.com', additionalParams = {}) {
  // const newDomain = 'https://newdomain.com'

  // const additionalParams = {
  //   newParam1: 'value1',
  //   newParam2: 'value2'
  // }

  const currentUrl = window.location.href

  const url = new URL(currentUrl)
  const newDomainUrl = new URL(newDomain)

  url.protocol = newDomainUrl.protocol
  url.host = newDomainUrl.host
  url.port = ''

  for (const [key, value] of Object.entries(additionalParams)) {
    url.searchParams.set(key, value) // Adds or updates the parameter
  }

  window.location.href = url.toString()
}

export {
  timeout,
  createComponent,
  redirectToNewDomain
}
