<template>
  <div class="h-full w-full max-w-full flex flex-col flex-1">
    <CreatorHeader/>

    <CreatorInfo/>

    <CreatorGifts
      v-if="creator.userId && options.gifts"
      :creator="creator"/>

    <CreatorContent/>
  </div>

  <Modal
    ref="subscribeModal"
    v-slot="{ closeModal, isVisible }"
    name="Subscribe"
    position="bottom"
    overflow-class="overflow-y-auto"
    rounded-class="rounded-t-2.5xl"
    content-class="bg-white px-6 pt-5 pb-4"
    :has-indents="false">
    <SubscribeContent
      :user="creator"
      :is-visible="isVisible"
      :close="closeModal"
      @subscribed="subscribed"/>
  </Modal>

  <Modal
    v-slot="{ values, closeModal }"
    ref="unsubscribeModal"
    name="Unsubscribe">
    <UnsubscribeContent
      :values="values"
      @unsubscribed="unsubscribed"
      @close="closeModal()"/>
  </Modal>

  <div class="max-w-md mx-auto fixed bottom-0 left-0 right-0 z-10">
    <button
      v-if="!creator.subscribed"
      type="button"
      :class="{ 'py-3.5': isWebView }"
      class="btn rounded-none btn-block btn-primary"
      @click="checkSubscription(creator.userId)">
      <template v-if="isAuthenticated">
        <template v-if="subscriptionPlansFirstPrice">
          {{ $t('creator.subscribe.for_price', { price: subscriptionPlansFirstPrice }) }}
        </template>

        <template v-else-if="subscriptionPriceInCurrency">
          {{ $t('creator.subscribe.for_price', { price: subscriptionPriceInCurrency }) }}
        </template>

        <template v-else>
          {{ $t('creator.subscribe.follow_for_free') }}
        </template>
      </template>

      <template v-else>
        {{ $t('creator.profile.sign_up_to_subscribe') }}
      </template>
    </button>

    <button
      v-if="quizAllowed"
      type="button"
      :class="{ 'py-3.5': isWebView }"
      class="btn rounded-none btn-block bg-[#a0a0af] hover:bg-slate-500 shadow-sm text-white"
      @click="surveyOpen = true; surveyCause = 'show_survey'">
      {{ $t('quiz.what_are_you_looking_for') }}
    </button>
  </div>
</template>

<script>
import CreatorHeader from '@/components/Creator/CreatorHeader'
import CreatorInfo from '@/components/Creator/CreatorInfo'
import CreatorContent from '@/components/Creator/CreatorContent'
import { useMeta } from 'vue-meta'
import Modal from '@/components/Functional/Modal'
import UnsubscribeContent from '@/components/ModalContents/UnsubscribeContent'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import { useAuthStore } from '@/stores/auth'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import { useAppStore } from '@/stores/app'
import helpers from '@/helpers'
import { useQuizStore } from '@/stores/quiz'
import CreatorGifts from '@/components/CreatorGifts/CreatorGifts.vue'

export default {
  name: 'TheCreator',
  components: {
    CreatorHeader,
    CreatorInfo,
    CreatorContent,
    Modal,
    UnsubscribeContent,
    SubscribeContent,
    CreatorGifts
  },
  setup () {
    const storeStore = useCreatorStore()
    const creator = storeStore.creator

    useMeta({
      title: `${creator.name} One2fan`,
      og: {
        title: `${creator.name} One2fan`,
        description: `${creator.name} Official One2fan`,
        site_name: 'Fancy',
        type: 'website'
      }
    })
  },
  data () {
    return {
      isWebView: helpers.isWebView()
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ]),
    ...mapState(useQuizStore, [
      'quizAllowed'
    ]),
    ...mapWritableState(useQuizStore, [
      'surveyOpen',
      'surveyCause'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isTokenExist',
      'options'
    ]),
    ...mapState(useCreatorStore, [
      'activeTab'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible',
      'pathToRedirect'
    ]),
    ...mapWritableState(useCreatorStore, [
      'creator',
      'videos',
      'images',
      'videosLoadingStatus',
      'imagesLoadingStatus'
    ]),
    ...mapState(useSubscriptionsStore, [
      'userHasPaidSubscription'
    ]),
    subscriptionPriceInCurrency () {
      return this.creator.subscriptionPlans?.length ? this.creator.subscriptionPlans[0].priceInCurrency : this.creator.subscriptionPriceInCurrency
    },
    subscriptionPlansFirstPrice () {
      return this.creator.subscriptionPlans?.length ? this.creator.subscriptionPlans[0].priceInCurrency : null
    }
  },
  mounted () {
    if (this.quizAllowed) {
      this.surveyShowButtonShown()
    }
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userPostsGet'
    ]),
    ...mapActions(useQuizStore, [
      'surveyShowButtonShown'
    ]),
    checkSubscription () {
      if (this.isAuthenticated) {
        if (this.creator.subscribed) {
          this.$refs.unsubscribeModal.openModal({ userId: this.creator.userId })
        } else {
          this.$refs.subscribeModal.openModal()
        }
      } else {
        helpers.showOnbordingOrSignUp()
        this.pathToRedirect = `/@${this.creator.username}`
      }
    },
    unsubscribed (user) {
      this.resetPosts(user)
      this.creator.subscribed = false
    },
    subscribed (user) {
      this.resetPosts(user)
      this.creator.subscribed = true
    },
    resetPosts (user) {
      /*
      * 1. Reset videos/images posts
      * 2. Reset videosLoadingStatus/imagesLoadingStatus
      * 3. Download fresh posts
      * 4. Update creator.subscribed state
      * */

      if (this.activeTab === 'videos') {
        this.videos = []
        this.videosLoadingStatus = ''

        this.userPostsGet({
          username: user.username,
          contentType: 'videos',
          limit: 9,
          beforeNumber: null
        })
      } else if (this.activeTab === 'images') {
        this.imagesLoadingStatus = ''
        this.images = []

        this.userPostsGet({
          username: user.username,
          contentType: 'images',
          limit: 9,
          beforeNumber: null
        })
      }
    }
  }
}
</script>

