<template>
  <form
    class="flex items-center"
    @submit.prevent="sendMessage">
    <FormValidationTooltip
      class="w-full"
      :content="inputError">
      <textarea
        id="chat_input"
        ref="textForm"
        type="text"
        :class="{
          'form-input-post post-shadow placeholder-gray-300': type === 'post',
          'w-full': isActive && type === 'post',
          'w-3/5': !isActive && type === 'post'
        }"
        rows="1"
        class="min-h-[40px] h-full form-input rounded-3xl text-base py-2 mr-1 transition-width duration-500 ease-in-out hide-scrollbar resize-none"
        style="-webkit-transform: translateZ(0);"
        :placeholder="placeholder"
        aria-label="Enter chat message"
        aria-describedby="send-button"
        :disabled="chatUser.deleted"
        @keydown="onKeyDown"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input="onInput($event); message = $event.target.value"/>
    </FormValidationTooltip>

    <ChatEmoji
      v-if="type === 'chat'"
      class="hidden sm:block"/>

    <div class="-mr-2">
      <button
        v-if="messageToSend"
        :disabled="!messageToSend || isFormError"
        class="text-primary hover:text-primary-press p-1"
        type="submit">
        <InlineSvg
          class="w-6 h-6"
          :src="require('@/assets/fontawesome/solid/paper-plane-top.svg')"/>
      </button>

      <template v-else-if="!chatUser?.deleted && chatUser?.username && !isPremiumSupportUser">
        <GiftsStarter
          v-if="(options.gifts && !isPremiumSupportUser) && options.gifts?.listStyle !== 'row'"
          :user="chatUser"
          type="grid"/>

        <TipsModal
          v-else-if="options.tipsEnabled"
          :user="chatUser"/>
      </template>
    </div>
  </form>
</template>

<script>

import ChatEmoji from '@/components/Chat/ChatForm/ChatEmoji'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import helpers from '@/helpers'
import FormValidationTooltip from '@/components/Functional/FormValidationTooltip'
import TipsModal from '@/components/TipsModal'
import { onCodeDown, cmd, ctrl, code_Enter } from 'on-hotkey'
import GiftsStarter from '@/components/Gifts/GiftsStarter.vue'

let unsubscribeCodeDown = null

export default {
  name: 'ChatTextForm',
  components: {
    ChatEmoji,
    FormValidationTooltip,
    TipsModal,
    GiftsStarter
  },
  props: {
    type: {
      type: String,
      default: 'chat'
    },
    userId: {
      type: String,
      default: ''
    }
  },
  emits: ['sent', 'update:is-focused'],
  data () {
    return {
      isFocused: false,
      inputError: '',
      textAreaHeight: 0
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'options'
    ]),
    ...mapWritableState(useChatStore, [
      'chatId',
      'messageToSend'
    ]),
    ...mapState(useChatStore, [
      'chatUser'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'chatPriceEnabled',
      'rates'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    placeholder () {
      if (this.premiumSupportAgentUserId.toString() === this.chatUser.userId) {
        return this.$t('chat.message.send_a_message')
      }

      if (this.type === 'chat') {
        if (this.chatPriceEnabled) {
          const cost = this.rates.costOfflineChat || this.rates.costOnlineChat

          return this.$t('chat.credit_per_message', { const: cost })
        }

        return this.$t('chat.message.send_a_message_with_price')
      } else {
        return this.$t('chat.message.message_me')
      }
    },
    message: {
      get () {
        return this.messageToSend
      },
      set (value) {
        this.messageToSend = value
      }
    },
    showSendButton () {
      if (this.type === 'chat') return true

      return this.isFocused || this.messageToSend
    },
    isActive () {
      return this.isFocused || this.messageToSend
    },
    isFormError () {
      return this.inputError.length > 0
    }
  },
  watch: {
    // Hack for iOS, issue with dynamic updating data
    message (value, oldValue) {
      this.$refs.textForm.value = value

      this.inputError = value.length > 300 ? this.$t('chat.message.at_most_300_characters') : ''

      if (oldValue && !value) {
        this.$refs.textForm.style.height = 'auto'
      }
    }
  },
  beforeMount () {},
  async mounted () {
    this.messageToSend = ''

    if (this.type === 'post' && this.userId) {
      this.chatId = this.userId
    }

    unsubscribeCodeDown = onCodeDown({
      keys: [{
        key: code_Enter,
        mods: [cmd]
      }, {
        key: code_Enter,
        mods: [ctrl]
      }]
    }, () => {
      this.sendMessage()
    })
  },
  unmounted () {
    unsubscribeCodeDown()
  },
  methods: {
    ...mapActions(useChatStore, [
      'messageSend'
    ]),
    async sendMessage () {
      if (this.isAuthenticated) {
        if (this.messageToSend) {
          this.messageSend()

          if (this.type === 'post') {
            this.$refs.textForm.blur()
          }

          this.$emit('sent')
          this.$refs.textForm.style.height = 'auto'
        }
      } else {
        helpers.showSignUp()
      }
    },
    onKeyDown (event) {
      // Get the code of pressed key
      // const keyCode = event.which || event.keyCode
      //
      // if (keyCode === 13 && !event.shiftKey) {
      //   // Don't generate a new line
      //   event.preventDefault()
      //   this.sendMessage()
      // }
    },
    onInput (event) {
      const element = this.$refs.textForm
      const offset = element.offsetHeight - element.clientHeight

      event.target.style.height = 'auto'
      event.target.style.height = event.target.scrollHeight + offset + 'px'
    }
  }
}
</script>

<style scoped>
  .form-input-post {
    caret-color: #0A84FF;
    @apply bg-transparent border-gray-300 focus:border-gray-300 focus:ring-opacity-0 text-white;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .resize-none {
    resize: none;
  }
</style>
