<template>
  <div
    v-if="!needToRedirect"
    class="h-full w-full max-w-md mx-auto bg-white">
    <ChatPlaceholder v-show="isLoading && !isError"/>

    <p v-if="isError">
      {{ $t('common.error_occurs') }}
    </p>

    <TheChat v-else/>
  </div>
</template>

<script>
  import TheChat from '@/components/Chat/TheChat'
  import { useChatStore } from '@/stores/chat'
  import { mapState, mapActions, mapWritableState } from 'pinia'
  import ChatPlaceholder from '@/components/Chat/ChatPlaceholder'
  import { isWebViewFullCheck } from '@/helpers/is-webview.js'
  import { redirectToNewDomain } from '@/helpers/utils.js'
  import { useAuthStore } from '@/stores/auth'
  import dayjs from '@/plugins/dayjsPluginNext.js'
  import app from '@/main'
  import { isNumber } from 'radash'

  export default {
    name: 'Chat',
    components: {
      ChatPlaceholder,
      TheChat
    },
    data () {
      return {
        constantLoading: true,
        isError: false,
        isFirstVisit: true,
        needToRedirect: false
      }
    },
    computed: {
      ...mapState(useAuthStore, [
        'options',
        'canonicalHostname'
      ]),
      ...mapWritableState(useChatStore, [
        'chatUser'
      ]),
      ...mapState(useChatStore, [
        'chatId',
        'chatLoading'
      ]),
      isLoading () {
        return this.chatLoading || this.constantLoading
      }
    },
    beforeCreate () {
      const chatStore = useChatStore()

      chatStore.chatId = this.$route.params.chatId
    },
    async beforeMount () {
      // Mirror escape
      const canonicalHostname = this.canonicalHostname
      const currentDomain = window.location.hostname
      let mirrorEscapeSigninToken = this.options?.mirrorEscape && this.options?.mirrorEscape?.signinToken.value
      const isDev = process.env.NODE_ENV === 'development'

      if (!isDev && mirrorEscapeSigninToken && canonicalHostname && currentDomain !== canonicalHostname && !isWebViewFullCheck()) {
        this.needToRedirect = true
        const mirrorEscapeTokenNextRefreshTs = this.options?.mirrorEscape && this.options?.mirrorEscape?.signinToken.nextRefreshTs
        const nextRefreshTime = dayjs.utc(mirrorEscapeTokenNextRefreshTs * 1000)
        const currentTime = dayjs.utc()
        const delay = nextRefreshTime.diff(currentTime)

        if (isNumber(delay) && delay <= 0) {
          try {
            const { data } = await app.wsp.sendRequest({
              data: {},
              method: 'mirrorEscape.refreshSigninToken'
            })

            if (data.signinToken?.value) {
              this.options.mirrorEscape.signinToken = data.signinToken
              mirrorEscapeSigninToken = data.signinToken.value
            }
          } catch (e) {
            console.error(e)
          }
        }

        redirectToNewDomain(`https://${canonicalHostname}`, {
          signin_token: mirrorEscapeSigninToken,
          from_mirror: '1'
        })
      }
    },
    async mounted () {
      if (this.chatId) {
        this.chatUser = {
          userId: null,
          age: null,
          name: '',
          sex: null,
          online: null,
          location: {
            name: ''
          },
          photos: {
            items: []
          },
          attachmentSupport: {
            photo: null
          },
          videoCallAvailability: null
        }

        setTimeout(() => {
          this.constantLoading = false
        }, 800)

        try {
          const res = await this.userGet()

          if (res?.error) {
            this.isError = true
          }
        } catch (e) {
          this.isError = true
        }
      }
    },
    methods: {
      ...mapActions(useChatStore, [
        'userGet'
      ])
    }
  }
</script>
