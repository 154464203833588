<template>
  <div
    :class="{
      'bg-red-50': type === 'error',
      'bg-green-50': type === 'success',
      'bg-yellow-50': type === 'warning',
      'bg-blue-50': type === 'info',
      'bg-gray-50': !type
    }"
    class="rounded-md p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <InlineSvg
          v-if="type === 'error'"
          class="h-5 w-5 text-red-400"
          :src="require('@/assets/svg/design/cross-circle.svg')"/>

        <svg
          v-else-if="type === 'success'"
          class="h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
        </svg>

        <svg
          v-else-if="type === 'warning'"
          class="h-5 w-5 text-yellow-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
        </svg>

        <svg
          v-else-if="type === 'info'"
          class="h-5 w-5 text-blue-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" clip-rule="evenodd" />
        </svg>
      </div>

      <div class="ml-3">
        <h3
          :class="{
            'text-red-800': type === 'error',
            'text-green-800': type === 'success',
            'text-yellow-400': type === 'warning',
            'text-blue-700': type === 'info'
          }"
          class="text-sm font-medium first-letter:capitalize">
          {{ heading }}
        </h3>

        <div
          v-if="text"
          :class="{
            'text-red-700': type === 'error',
            'text-green-700': type === 'success',
            'text-yellow-800': type === 'warning',
            'text-blue-700': type === 'info'
          }"
          class="mt-2 text-sm">
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'AlertMessage',
  components: {
    InlineSvg
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    heading: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

