<script setup>
  import { ref, computed, onMounted } from 'vue'
  import app from '@/main.js'
  import { useTimeout } from '@vueuse/core'
  import CreatorGiftsRowItem from '@/components/CreatorGifts/Include/CreatorGiftsRowItem.vue'
  import { createComponent } from '@/helpers/utils.js'
  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import CreatorGiftsModal from '@/components/CreatorGifts/Include/CreatorGiftsModal.vue'

  const props = defineProps({
    creator: {
      type: Object,
      default: () => ({}),
      required: true
    }
  })

  const gifts = ref([])
  const errorMessage = ref(null)
  const loading = ref(false)
  const { ready, start, stop } = useTimeout(500, { controls: true, immediate: false })

  const isLoading = computed(() => {
    return loading.value || !ready.value
  })

  async function userGetGiftReceivingCounts () {
    try {
      if (isLoading.value) {
        return
      }

      errorMessage.value = null

      const { data } = await app.wsp
        .sendRequest({
          data: {
            id: props.creator.userId + ''
          },
          method: 'user.getGiftReceivingCounts'
        })

      gifts.value = data.giftReceivingCounts
      loading.value = true
      start()
    } catch (error) {
      errorMessage.value = error?.message ? error.message : 'Unknown error'
      stop()

      console.log(error)
    } finally {
      loading.value = false
    }
  }

  function openCreatorGiftsCatalogModal () {
    const modalProps = {
      wrapperClass: 'max-w-md m-auto w-full h-full flex mt-4'
    }

    const contentProps = {
      items: gifts.value,
      creator: props.creator
    }

    createComponent(ModalWindow, modalProps, CreatorGiftsModal, contentProps)
  }

  onMounted(async () => {
    userGetGiftReceivingCounts()
  })
</script>

<template>
  <div
    v-if="isLoading || gifts.length"
    class="flex gap-4 items-center w-full max-w-md mx-auto pb-5 px-4 mt-4">
    <div
      v-if="!errorMessage"
      class="grid grid-cols-5 gap-[6%] w-full">
      <template v-if="isLoading">
        <div
          v-for="_ in 5"
          :key="_"
          class="w-full p-1">
          <div class="w-full rounded-full aspect-square skeleton-loading"/>
        </div>
      </template>

      <CreatorGiftsRowItem
        v-for="item in gifts.slice(0, 5)"
        v-show="!isLoading"
        :key="item.gift.id"
        :item="item"/>
    </div>

    <button
      v-if="!errorMessage"
      type="button"
      class="bg-gray-100 rounded w-8 h-8 flex items-center justify-center flex-shrink-0 hover:bg-gray-200"
      @click="openCreatorGiftsCatalogModal">
      <InlineSvg
        :src="require('@/assets/fontawesome/regular/chevron-up.svg')"
        class="w-4 h-4 text-gray-500"/>
    </button>

    <div
      v-if="errorMessage"
      class="text-xs text-red-400 leading-3">
      {{ errorMessage }}
    </div>
  </div>
</template>