<script setup>
import { ref } from 'vue'
import EmojiPicker from '@/components/Chat/ChatForm/EmojiPicker.vue'
import { useChatStore  } from '@/stores/chat'
const isOpen = ref(false)

const chatStore = useChatStore()

function close () {
  isOpen.value = false
}
</script>

<template>
  <div class="relative z-20">
    <button
      ref="emojiTrigger"
      tabindex="0"
      :disabled="chatStore.chatUser.deleted"
      type="button"
      class="btn p-1 text-gray-400 hover:text-gray-500"
      @click.stop="isOpen = !isOpen">
      <InlineSvg
        class="w-6 h-6"
        :src="require('@/assets/fontawesome/solid/face-grin.svg')"/>
    </button>

    <transition name="fade">
      <EmojiPicker
        v-show="isOpen"
        v-click-outside="close"
        class="absolute z-50 bottom-13 -left-[170px]"/>
    </transition>
  </div>
</template>
