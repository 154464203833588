<template>
  <div class="flex flex-wrap items-center w-100">
    <div class="message companion">
      <div class="flex justify-end items-end">
        {{ message.text }}

        <div class="flex items-center">
          <div class="text-caption text-gray-400 px-1 whitespace-nowrap flex justify-center content-center">
            {{ dateToTime(message.created) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap items-center w-100">
    <div class="message companion">
      <h4><b>@{{ chatUser.username }}</b> <span class="text-gray-800">added a new post!</span></h4>

      <p class="text-gray-800 pb-1">View it now:</p>

      <a
        class="flex text-caption leading-4 break-words text-link mb-1.5 cursor-pointer"
        @click="openPost(message)">
        {{ host }}{{ url }}
      </a>

      <a
        class="flex flex-wrap justify-end items-end cursor-pointer"
        @click="openPost(message)">
        <div class="w-100 flex-1 flex items-start justify-between">
          <div class="border-l-2 pl-2 border-[#00aed5]">
            <h5 class="font-bold pb-1 text-sm">@{{ chatUser.username }}</h5>

            <p class="text-caption leading-5 text-gray-500">
              {{ message.post.description }}
            </p>
          </div>

          <div class="pl-2">
            <ImageLoader
              v-slot="{ setLoaded }"
              class="w-14 h-18 rounded-md bg-gray-200">
              <img
                :src="message?.post.previewImageUrl"
                class="block object-cover w-full h-full rounded-md"
                alt=""
                @load="imageLoaded(setLoaded)">
            </ImageLoader>
          </div>
        </div>
      </a>

      <div class="text-caption text-gray-400 mt-1 leading-4 whitespace-nowrap text-right">
        {{ dateToTime(message.created) }}
      </div>
    </div>
  </div>

  <Modal
    ref="chatPostsContent"
    v-slot="{ values, closeModal }"
    name="ChatPost"
    :is-rounded="false"
    :has-indents="false"
    :is-full-height="true"
    :show-close-cross="false"
    content-class="bg-gray-700"
    position="center">
    <ChatPostContent
      :values="values"
      @close="closeModal()"/>
  </Modal>
</template>

<script>

import ImageLoader from '@/components/Functional/ImageLoader'
import Modal from '@/components/Functional/Modal'
import ChatPostContent from '@/components/ModalContents/chat/ChatPostContent'
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useChatStore } from '@/stores/chat'

export default {
  name: 'ChatPostMessage',
  components: {
    ImageLoader,
    Modal,
    ChatPostContent
  },
  props: {
    message: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      host: window.location.host
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'userId',
      'balance',
      'rates'
    ]),
    ...mapState(useChatStore, [
      'chatUser',
      'sortedMessagesPostsType'
    ]),
    url () {
      return '/' + this.message.post.id
    }
  },
  mounted () {
  },
  methods: {
    dateToTime (value) {
      const date = this.$dayjs.unix(value)

      return date.locale(this.$i18n.locale).format('h:mm A')
    },
    imageLoaded (callback) {
      callback()
    },
    openPost (message) {
      if (!message?.post) return

      this.$refs.chatPostsContent.openModal({
        post: message.post,
        user: this.chatUser
      })
    }
  }
}
</script>

<style scoped>
  .message {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative;
    max-width: 80%;
  }

  .companion {
    @apply bg-white text-gray-900;
    filter: drop-shadow(0px 5px 10px rgba(188, 203, 209, 0.4));
  }

  .companion:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail.svg");
    position: absolute;
    bottom: 0;
    left: -6px;
  }
</style>
