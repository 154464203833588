<script setup>
  import { ref, watch } from 'vue'
  import { isString } from 'radash'

  const props = defineProps({
    text: {
      type: String,
      default: '',
      required: false
    }
  })

  const localText = ref('')

  watch(() => props.text, (value) => {
    if (isString(value) && value.length > 0) {
      localText.value = value
    } else {
      setTimeout(() => {
        localText.value = ''
      }, 200)
    }
  }, { immediate: true })
</script>

<template>
  <transition name="fade">
    <span
      v-show="text"
      class="tooltip1 text-white rounded bg-gray-950 text-xs">
      {{ localText }}
    </span>
  </transition>
</template>

<style scoped>
  .tooltip1 {
    text-align: center;
    border-radius: 4px;
    padding: 7px 8px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 113%;
    margin: auto;
    width: max-content;
  }

  .tooltip1::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-width: 6px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
</style>
