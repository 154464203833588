<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { createComponent } from '@/helpers/utils.js'
  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import GiftsCatalogModal from '@/components/Gifts/GiftsCatalogModal.vue'
  import GiftsRowItem from '@/components/Gifts/GiftsRow/GiftsRowItem.vue'
  import GiftsConfirmationSendModal from '@/components/Gifts/GiftsConfirmationSendModal.vue'
  import app from '@/main.js'
  import { useTimeout } from '@vueuse/core'
  import uniqid from 'uniqid'
  import emitter from '@/plugins/mittPlugin.js'

  const props = defineProps({
    user: {
      type: Object,
      default: () => ({}),
      required: true
    },
    type: {
      type: String,
      default: 'row',
      required: true
    },
    iconClass: {
      type: String,
      required: false,
      default: 'text-gray-400 hover:text-gray-500'
    }
  })

  const gifts = ref([])
  const loading = ref(false)
  const requestSuccessful = ref(false)

  const { ready, start, stop } = useTimeout(500, { controls: true, immediate: false })

  const isLoading = computed(() => {
    return loading.value || !ready.value
    // return true
  })

  onMounted(async () => {
    try {
      if (isLoading.value) {
        return
      }

      loading.value = true
      start()

      const { data } = await app.wsp
        .sendRequest({
          data: {},
          method: 'gifts.get'
        })

      requestSuccessful.value = true

      gifts.value = data.gifts
    } catch (error) {
      stop()

      requestSuccessful.value
      console.log(error)
    } finally {
      loading.value = false
    }
  })

  const errorMessage = ref(null)
  const giftSendLoading = ref(false)

  async function sendFreeGift (item) {
    if (giftSendLoading.value) {
      return
    }

    giftSendLoading.value = true
    errorMessage.value = null

    try {
      const response = await app.wsp
        .sendRequest({
          data: {
            receiverUserId: props.user.userId,
            clientId: uniqid(),
            giftId: item.id,
            purchaseCreditsIfNotEnough: false
          },
          method: 'gift.send'
        })

      if (response.error) {
        const error = response.error

        throw new Error(error?.message ? error.message : 'Unknown error', {
          cause: error
        })
      }

      errorMessage.value = null
      emitter.emit('close-gifts-to-send-modal')
    } catch (error) {
      console.log(error)
      errorMessage.value = error?.message ? error.message : 'Unknown error'
    } finally {
      giftSendLoading.value = false
    }
  }

  async function handleGiftClick (item) {
    if (item.priceInCredits === 0) {
      await sendFreeGift(item)
    }
    else {
      openGiftsConfirmationSendModal(item)
    }
  }

  function openGiftsConfirmationSendModal (gift) {
    const modalProps = {
      wrapperClass: 'max-w-md m-auto w-full flex'
    }

    const contentProps = {
      user: props.user,
      gift: gift
    }

    createComponent(ModalWindow, modalProps, GiftsConfirmationSendModal, contentProps)
  }

  function openGiftsCatalogModal () {
    const modalProps = {
      wrapperClass: 'max-w-md m-auto w-full h-full flex mt-4'
    }

    const contentProps = {
      items: gifts.value,
      user: props.user,
      openGiftsConfirmationSendModal: openGiftsConfirmationSendModal,
      sendFreeGift: sendFreeGift
    }

    createComponent(ModalWindow, modalProps, GiftsCatalogModal, contentProps)
  }
</script>

<template>
  <div
    v-if="type === 'row' && (isLoading || gifts.length)"
    class="flex gap-4 items-center w-full max-w-md mx-auto">
    <div class="grid grid-cols-5 gap-[6%] w-full">
      <template v-if="isLoading">
        <div
          v-for="_ in 5"
          :key="_"
          class="w-full p-1">
          <div class="w-full rounded-full aspect-square skeleton-loading"/>
        </div>
      </template>

      <GiftsRowItem
        v-for="item in gifts.slice(0, 5)"
        v-show="!isLoading"
        :key="item.id"
        :item="item"
        class="cursor-pointer"
        @click="handleGiftClick(item)"/>
    </div>

    <button
      type="button"
      class="bg-gray-100 rounded w-8 h-8 flex items-center justify-center flex-shrink-0 hover:bg-gray-200"
      @click="openGiftsCatalogModal">
      <InlineSvg
        :src="require('@/assets/fontawesome/regular/chevron-up.svg')"
        class="w-4 h-4 text-gray-500"/>
    </button>

    <div
      v-if="errorMessage"
      class="text-xs text-red-400 leading-3">
      {{ errorMessage }}
    </div>
  </div>

  <slot v-else :open-gifts-catalog-modal="openGiftsCatalogModal">
    <button
      :class="iconClass"
      class="p-1"
      type="button">
      <InlineSvg
        class="w-6 h-6"
        :src="require('@/assets/fontawesome/regular/gift.svg')"
        @click="openGiftsCatalogModal"/>
    </button>
  </slot>
</template>